.card-wrapper {
    width: 32%;
    padding: 2em;
    box-shadow: 0px 10px 25px rgba(0, 0, 51, 0.08);
    border-radius: 20px;

    @media screen and (max-width: 768px) {
        width: 100%;
        margin-bottom: 2em;
    }

    .icon {
        width: 40px;
        height: 40px;
    }

    .card-header {
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
    }

    .card-content {
        color: #56565695;
        line-height: 1.84;
    }
}

.avatar {
    width: 60px;
    height: 60px;

    @media screen and (max-width: 768px) {
        width: 48px;
        height: 48px;
    }
}