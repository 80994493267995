

h1 {
    font-size: 42px;
    font-weight: 700;
    line-height: 1.55;
    font-family: 'Ubuntu', sans-serif;

    @media screen and (max-width: 768px) {
        margin-top: 1em;
        font-size: 30px;
        line-height: 40px;
    }
}

.banner-contenet {
    font-size: 18px;
    line-height: 26px;
    color: #565656;
    margin-bottom: 4em;

    @media screen and (max-width: 768px) {
        margin-bottom: 3em;
    }
}

img {
    width: 100%;
}

.about-us {
    background: #F9F9F9;
}

.about-us-content {
    width: 100%;
    max-width: 875px;  
}

iframe {
    width: 729px;
    height: 400px;
    margin: 1.5em 1em;
}

.brand-content {
    font-size: 22px;
    line-height: 25px;
    color: #807D7D;

    @media screen and (max-width: 768px) {
        margin-top: 8px;
        font-size: 16px;
        line-height: 18px;
    }
    
}

.brand-logo {
    width: 102.9px;
    height: 64.31px;
    margin-right: 72px;
}

.brand-logo-slim {
    width: 57.6px;
    height: 64px;
    margin-right: 72px;
}

.testimonial-content {
    font-size: 16px;
    line-height: 18px;
}

.avatar-name {
    font-weight: 500;
    font-size: 20px;
    line-height: 43px;

    @media screen and (max-width: 768px) {
        font-size: 16.3019px;
        line-height: 35px;
    }
}
.quote {
    width: 22px;
    height: 22px;

    @media screen and (max-width: 768px) {
        width: 16px;
        height: 16px;
    }
}