@import 'assets/styles/index.scss';
@import 'bootstrap/dist/css/bootstrap.css';

* {
    box-sizing: border-box;
    font-family: 'Ubuntu', sans-serif;

}

html, body {
    padding: 0;
    margin: 0;
}

.container {
    padding-left: 2em;
    padding-right: 2em;
}