.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.select__button {
  width: 100%;
  display: inline-block;
  text-align: left;
  cursor: pointer;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: visible;
}

/*.select__button:focus {
  outline: auto !important;
}*/

/*.select__wrapper,
.select__wrapper *:focus {
  outline: none;
}*/

.select__wrapper.disabled,
.select__wrapper.disabled div {
  cursor: not-allowed;
}

.select__wrapper {
  cursor: pointer;
  outline: none;
  position: relative;
  height: 100%;
}

.select__input {
  opacity: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.select__buttonWrapper {
  height: 100%;
}

.select__searchInputWrapper {
  padding: 6px;
  box-sizing: border-box;
  width: 100%;
  position: relative;
  display: flex;
  background-color: #fff;
}

.select__searchInputSearchIcon {
  position: absolute;
  top: 50%;
  left: 8px;
  transform: translateY(-50%);
}

.select__searchInputRemoveIcon {
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
  cursor: pointer;
}

.select__searchInput {
  padding: 0 20px;
  box-sizing: border-box;
  border-radius: 2px;
  font-size: 14px;
  height: 40px;
  width: 100%;
  border: 1px solid #dfe2e7;
  color: #7f8fa4;
  display: block;
}

.select__container {
  position: relative;
  height: 100%;
}

.select__options-item {
  cursor: none;
  background: #fff;
  color: #4a4a4a;
  transition: background 0.4s, color 0.2s;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.select__options-item div {
  display: inline-block\9;
  text-align: right;
}

.select__options-item-show-cursor {
  cursor: pointer;
}

.select__no-mouse {
  cursor: none;
}

.select__hover-active {
  background: #ff5a57;
  color: #fff;
}

.select__options-item.active {
  background: #d3d3d3;
  color: #fff;
}

.select__options-container-animate {
  opacity: 0;
  filter: alpha(opacity=0);
  position: absolute;
  left: 0;
  z-index: -1;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.12), 0 0 10px 0 rgba(0, 0, 0, 0.08);
  transform: translateY(-10px);
  transition: all 0.4s;
  visibility: hidden;
}

.select__options-container-animate.show {
  transform: translate(-50%, 0) scale(1, 1);
  opacity: 1;
  z-index: 3;
  visibility: visible;
}

.select__options-container {
  opacity: 0;
  filter: alpha(opacity=0);
  position: absolute;
  left: 0;
  z-index: -1;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.12), 0 0 10px 0 rgba(0, 0, 0, 0.08);
  transform: translateY(-10px);
  visibility: hidden;
}

.select__options-container.show {
  transform: translateY(0);
  opacity: 1;
  filter: alpha(opacity=100);
  z-index: 1000;
  visibility: visible;
}

.select__dropdown {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.select__dropdown-icon {
  position: relative;
  width: 2%\9;
  display: inline-block\9;
  vertical-align: middle\9;
  width: 10px;
  height: 20px;
}

.select__selector {
  padding: 0 10px;
  height: 100%;
}

.select__dropdown-flag {
  flex: 0 0 30%;
  width: 30%\9;
  display: inline-block\9;
  vertical-align: middle\9;
  height: 100%;
}

.select__dropdown-name {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 0 0 70%;
  width: 68%\9;
  text-align: right\9;
  display: inline-block\9;
  vertical-align: middle\9;
}

.select__dropdown-name div {
  display: inline-block\9;
  vertical-align: middle\9;
}

.select__dropdown-icon.showArrow:before {
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border-style: solid;
  display: block;
  width: 0;
  border-width: 5px 5px 0;
  border-color: #e3e3e3 transparent;
}

.select__dropdown-icon-container {
  position: relative;
}
