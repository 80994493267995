@import 'assets/styles/color.scss';

$brand-width: 200px;

nav {
    width: 100%;
    background-color: #fff;
}

.brand {
    width: $brand-width;
    height: 45px;
}

#navbarSupportedContent {
    width: calc(100% - $brand-width);

    .navbar-nav {
        width: 100%;

        .nav-item {
            margin: 0 20px;
        }

        .active {
            color: $primary;
            border-bottom: 2px solid $primary;
        }

        .login {
            color: $primary;
        }

        .get-started {
            color: $primary;
            padding: 14px 21px;
            border-radius: 6px;
            border: 1px solid $primary;
        }
    }
}

@media (max-width: 1024px) {
    .nav-item {
        margin: 0 8px !important;
    }
}

@media (max-width: 768px) {
    .brand {
        width: 150px !important;
        height: 35px !important;
    }

    .navbar-nav {
        display: block;

        .nav-item {
            margin: 8px 0 !important;
        }
    }
}