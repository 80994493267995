@import './typography.scss';
@import './color.scss';
@import './card.scss';
@import './padding.scss';
@import './margin.scss';

button {
    border: none;
    color: #fff;
    border-radius: 6px;
    padding: 14px 21px;
    background-color: $primary;
}

.button-outline {
    border: 1px solid $primary;
    color: $primary;
    background-color: #fff;
}

.icon {
    width: 26px;
    height: 32px;
}

.comment-wrapper {
    margin-top: 4em;
    margin-bottom: 4em;
}

.comment {
    p, p span {
        font-weight: 500;
        font-size: 32px;
        line-height: 1.25;
    }

    p {
        color: #828282;

        span {
            color: 
            #BDBDBD
        }
    }

    .commenter {
        font-weight: 500;
        font-size: 18px;
        line-height: 40px;
        color: #000;
    }
}

.comment-quote {
    width: 42px;
    height: 30px;
    margin: 20px;
}

.dot-grid {
    position: absolute;
    width: 145px;
    height: 145px;
    left: -80px;
    bottom: -70px;
    z-index: -11;
    background: #fff;
    background-image: radial-gradient(#00003326 5px, transparent 0);
    background-size: 25px 25px;
}

.dot-grid-2 {
    position: absolute;
    width: 130px;
    height: 130px;
    right: 0px;
    top: 70px;
    z-index: -1;
    background: #fff;
    background-size: 25px 25px;
    background-image: radial-gradient(#00003326 5px, transparent 0);
}

.grid-wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 49px;
    grid-row-gap: 49px;

    .grid-image-1 { 
        height: 100%; width: 100%;
        grid-area: 1 / 1 / 3 / 2;
    }
    .grid-image-2 { 
        grid-area: 3 / 1 / 4 / 2;
    }
    .grid-image-3 { 
        grid-area: 1 / 2 / 4 / 5;
    } 

    @media screen and (max-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-column-gap: 16px;
        grid-row-gap: 36px;

        .grid-image-1 { 
            grid-area: 1 / 1 / 2 / 2;
        }
        .grid-image-2 { 
            height: 100%; width: 100%;
            grid-area: 1 / 2 / 2 / 3;
        }
        .grid-image-3 { 
            grid-area: 2 / 1 / 3 / 3;
        } 
    }
}

.careers-header {
    @media screen and (max-width: 768px) {
        font-size: 22px;
        line-height: 1.45;
    }
}

.icon-circle {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: rgba(80, 172, 222, 0.1);
}

.position-wrapper {
    box-shadow: 0px 4px 16px rgba(75, 75, 75, 0.08);
    border-radius: 8px;
    padding: 1.5em 3em;
}

.position-name {
    font-size: 22px;
    line-height: 1.36;
    letter-spacing: -0.02em;
}

.position-dept {
    font-size: 18px;
    line-height: 30px;
    letter-spacing: -0.02em;
    color: #828282;
}

.position-link {
    text-decoration: none;

    .position-apply {
        font-size: 20px;
        line-height: 1.5;
        letter-spacing: -0.02em;
        color: #50ACDE;
    }
}

.contact-header {
    font-weight: 500;
    font-size: 42px;
    line-height: 80px;
    letter-spacing: -0.03em;
    color: #333333;

    @media screen and (max-width: 768px) {
        font-size: 30px;
        line-height: 40px;
    }
}

.contact-banner {
    font-size: 20px;
    line-height: 35px;
    letter-spacing: -0.02em;
    color: #565656;

    @media screen and (max-width: 768px) {}
}


.form-group {
    margin-bottom: 2em;
    label, input, textarea {
        display: block;
    }

    label {
        margin-bottom: 8px;
        font-weight: 500;
        line-height: 20px;
    }

    .field-input {
        padding: 12px;
        border: 1.5px solid #C0C4CC;
        border-radius: 8px;
        width: 90%;
        appearance: none;

        &::placeholder, input::placeholder {
            font-size: 16px;
            line-height: 22px;
            color: #C0C4CC;
        }

        &:focus{
            outline: none;
        }

        @media screen and (max-width: 768px) {
            width: 100%;
        }
    }

    .phone-input {
        input {
            box-shadow: none;
            border: none;
            appearance: none;

            &:focus{
                outline: none;
            }
        }
    }
}

@for $i from 1 through 50 {
    .width-#{$i} {
      width: '#{$i}%';
    }
}
  
.full-width {
    width: 90%;

    @media screen and (max-width: 768px) {
        width: 100%;
    }
}

.react-custom-flag-select__select__dropdown-name div:first-child {
    color: #000;
}

.contact-label {
    font-size: 20px;
    line-height: 1.75;
    color: #565656;
}

.contact-detail {
    font-size: 22px;
    line-height: 1.6;
    color: #333333;
    text-decoration: none;
}

.policy-header {
    font-weight: 700;
    font-size: 22px;
    line-height: 28px;
    color: #565656;
    margin: 1.5em 0 1em;
}

.policy-text, .policy-list li {
    font-size: 18px;
    line-height: 28px;
    word-wrap: break-word;
}

.error-field {
    border: 2px solid#FF2400 !important;
}

.error {
    color: #FF2400;
}

.hint {
    font-size: 13px;
    color: #909399;
}