@import './color.scss';

* {
  font-size: 14px;
  font-weight: 400px;
  line-height: normal;
  font-family: "Roboto", sans-serif;
}

.header {
  font-size: 36px;
  font-weight: 500;
  line-height: 1.5;
  color: #333333;

  @media screen and (max-width: 768px) {
    font-weight: 500;
    font-size: 20px;
    line-height: 1.5;
  }
}

.header-content {
  font-size: 20px;
  line-height: 1.5;
}


.section-header {
  font-weight: 500;
  font-size: 36px;
  line-height: 1.19;
  color: $bold;

  @media screen and (max-width: 768px) {}
}

.section-content {
  font-size: 16px;
  line-height: 1.62;

  @media screen and (max-width: 768px) {}
}

@for $i from 300 through 900 {
  .font-weight-#{$i} {
    font-weight: $i;
  }
}

@for $i from 10 through 78 {
  .text-#{$i} {
    font-size: #{$i}px;
  }
}
