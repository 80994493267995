@for $i from 1 through 50 {
  .py-em-#{$i} {
    padding-top: #{$i}em;
    padding-bottom: #{$i}em;
  }

  .px-em-#{$i} {
    padding-left: #{$i}em;
    padding-right: #{$i}em;
  }

  .pl-em-#{$i} {
    padding-left: #{$i}em;
  }

  .pr-em-#{$i} {
    padding-right: #{$i}em;
  }

  .pb-em-#{$i} {
    padding-bottom: #{$i}em;
  }

  .pt-em-#{$i} {
    padding-top: #{$i}em;
  }
}

@for $i from 1 through 200 {
  .py-px-#{$i} {
    font-weight: #{$i}px;
  }
  .px-px-#{$i} {
    font-weight: #{$i}px;
  }
  .pl-px-#{$i} {
    font-weight: #{$i}px;
  }
  .pr-px-#{$i} {
    font-weight: #{$i}px;
  }
  .pb-px-#{$i} {
    font-weight: #{$i}px;
  }
  .pt-px-#{$i} {
    font-weight: #{$i}px;
  }
}
