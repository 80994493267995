@for $i from 1 through 50 {
  .my-em-#{$i} {
    margin-top: #{$i}em;
    margin-bottom: #{$i}em;
  }

  .mx-em-#{$i} {
    margin-left: #{$i}em;
    margin-right: #{$i}em;
  }

  .ml-em-#{$i} {
    margin-left: #{$i}em;
  }

  .mr-em-#{$i} {
    margin-right: #{$i}em;
  }

  .mb-em-#{$i} {
    margin-bottom: #{$i}em;
  }

  .mt-em-#{$i} {
    margin-top: #{$i}em;
  }
}
