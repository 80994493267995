@import 'assets/styles/color.scss';

.get_started {
    background-color: #F9F9F9;
    padding-top: 55px;
    padding-bottom: 55px;
}

.spaced-content {
    line-height: 1.54 !important;
}

.footer-heading {
    color: #4F4F4F;
    font-size: 16px;
    font-weight: 500;
}

.nav-link {
    margin: 1.5em 0;
}

.copyright {
    font-size: 14px;
    color: #828282;
}

