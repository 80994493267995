$primary: #50ACDE;
$bold: #333333;
$reg: #565656;

.primary-bg {
    background-color: $primary;
}

.grey {
    color: #909399;
}

.light-grey {
    background: #F9F9F9;
}